<template>
  <div>
    <section>
      <button
        class="uk-button uk-button-default uk-border-rounded uk-margin-right"
        style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
        @click="saveData"
      >Save</button>
      <button
        class="uk-button uk-button-default uk-border-rounded uk-margin-right"
        style="border-color: #0ABAB5; color: #0ABAB5"
        @click="$emit('action', false)"
      >Cancel</button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
        v-for="(detail, dKey) in details"
        :key="dKey"
      >
        <div class="uk-width-1-2 uk-margin-auto-vertical">{{ detail.label }}</div>

        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <!-- Input File -->
            <div v-if="detail.type === 'file'" class="uk-width-expand">
              <!-- IF FILE EXIST -->
              <div v-if="form[detail.value]" class="uk-form-controls">
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between uk-flex-middle"
                  style="
                    height: 40px;
                    padding: 0 30px;
                    border-radius: 5px;
                    border: 1px solid #0abab5;
                    color: #0abab5;
                  "
                >
                  <span
                    style="
                      width: 90%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    "
                    :title="form[detail.value].file_path"
                  >
                    {{ form[detail.value].file_path }}
                  </span>
                  <img
                    :src="`${images}/cross.svg`"
                    alt="close icon"
                    width="15"
                    heigth="15"
                    style="cursor: pointer;"
                    @click="form[detail.value] = null"
                  />
                </div>
              </div>
              <!-- IF NO FILE -->
              <div v-else class="uk-form-controls">
                <button
                  class="uk-button uk-button-default uk-width-1-1"
                  style="
                    border-radius: 5px;
                    border: 1px solid #0abab5;
                    color: #0abab5;
                  "
                  type="button"
                  @click="triggerUpload(detail)"
                >
                  <img :src="`${images}/shift-download.svg`" alt="" width="25">
                  Upload File
                </button>
              </div>
            </div>
            <!-- DATE INPUT -->
            <div v-else-if="detail.type === 'date'" class="uk-width-expand">
              <datetime
                  name="date"
                  id="date"
                  input-class="uk-input uk-border-rounded"
                  type="date"
                  format="dd LLL yyyy"
                  :max-datetime="detail.value === 'start_date' ? datetime.now().toISODate() : ''"
                  v-model="form[detail.value]"
              ></datetime>
            </div>
            <!-- SELECT INPUT -->
            <div v-else-if="detail.type === 'select'" class="uk-width-expand">
              <select v-model="form[detail.value]" class="uk-select">
                <option v-for="option in detail.option" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <!-- Employment Type -->
            <div v-else-if="detail.type === 'employment_type'" class="uk-width-expand">
                <div v-if="(form[detail.value] || []).length > 0" class="uk-flex uk-flex-left uk-flex-middle">
                    <p
                        v-for="(employmentType, index) in (form[detail.value] || [])"
                        :key="index"
                        class="uk-margin-remove-top uk-margin-remove-bottom"
                        :class="[index !== ((form[detail.value] || []).length -1) ? 'uk-margin-small-right' : '']"
                    >{{ employmentType }}<span v-if="index !== ((form[detail.value] || []).length -1)">,</span> </p>
                </div>
                <span v-else>-</span>
            </div>
            <!-- TEXT INPUT -->
            <div v-else class="uk-width-expand">
              <input
                v-model="form[detail.value]"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
          <div v-if="errorsD[detail.value]" class="uk-text-danger">{{ errorsD[detail.value] }}</div>
        </div>
      </div>
    </section>
    <!-- Upload Input -->
    <input ref="upload" style="display: none" type="file" @change="uploadFileVerification" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDangerCustom } from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import {PREFIX_IMAGE} from '@/utils/constant';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    components: { Datetime },
    data() {
        return {
            images: PREFIX_IMAGE,
            datetime: DateTime,
            isLoading: false,
            uploadDocType: null,
            details: [
                {
                    label: 'Employment Type',
                    value: 'employment_type',
                    type: 'employment_type'
                },
                { label: 'Posisi', value: 'position', type: "text" },
                { label: 'Divisi', value: 'division', type: "text" },
                { label: 'Hub Penempatan', value: 'placement', type: "text" },
                { label: 'Tanggal Mulai Bekerja', value: 'start_date', type: "date" },
                {
                    label: 'Status SIM',
                    value: 'sim_status',
                    type: "select",
                    option: [
                        { text: "Aktif", value: true },
                        { text: "Kadaluarsa", value: false },
                    ]
                },
                { label: 'Nomor SIM', value: 'sim_number', type: "text" },
                { label: 'Tanggal Kadaluarsa SIM', value: 'sim_expired_date', type: "date" },
                { label: 'Foto SIM', value: 'other_document_sim', type: "file", api: "SIM" },
                { label: 'Foto STNK', value: 'other_document_stnk', type: "file", api: "STNK" },
                { label: 'Nomor Polisi Kendaraan (Motor/Mobil)', value: 'vehicle_number', type: "text" },
                { label: 'Nomor Induk Pegawai MYR', value: 'myr_id', type: "text" },
                { label: 'Client Mitra ID', value: 'loop_id', type: "text" },
                { label: 'Kontak Perusahaan Sebelumnya', value: 'previous_company_contact' },
                { label: 'Nama Pihak Perusahaan Sebelumnya', value: 'previous_company_name' },
                { label: 'Hubungan Pihak Perusahaan Sebelumnya', value: 'previous_company_role' },
            ],
            form: { ...this.$props.data, ...this.$props.data.detail.job },
            errorsD: {},
        };
    },
    methods: {
        ...mapActions({
            uploadDocument: 'other_document/uploadDocument',
            patchJobDetail: 'mitra/patchJobDetail',
        }),

        triggerUpload(data) {
            this.uploadDocType = data;
            this.$refs.upload.click();
        },

        async uploadFileVerification(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    const filepath = e.target.files[0];
                    let formData = new FormData();
                    formData.append('file', filepath);

                    const result = await this.uploadDocument({
                        api: this.uploadDocType.api,
                        user_id: this.$route.params.mitra_id,
                        payload: formData
                    });

                    if (result.status === 'OK') {
                        notificationSuccess(`Upload successful`);
                        this.form[this.uploadDocType.value] = result.result.document_url;
                    } else {
                        notificationDangerCustom('Failed to upload!');
                    }

                    this.$refs.upload.value = null;
                }
            }
        },

        checkFile(fileSize, fileExt) {
            if (fileSize / 1024 / 1024 > 10) {
                notificationDangerCustom('File terlalu besar, maksimal file berukuran 10 MB');
                this.$refs.upload.value = null;
                return false;
            }

            const extension = ["png", "jpg", "jpeg", "pdf"];
            const splitImg = fileExt.split("/");
            let imgMime = splitImg[splitImg.length - 1];

            if (!extension.includes(imgMime)) {
                notificationDangerCustom('Ekstensi file tidak dikenali');
                this.$refs.upload.value = null;
                return false;
            }

            return true;
        },

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                "detail": {
                    "job": {
                        "position": !this.form.position || this.form.position === ""
                            ? null : this.form.position,
                        "division": !this.form.division || this.form.division === ""
                            ? null : this.form.division,
                        "placement": !this.form.placement || this.form.placement === ""
                            ? null : this.form.placement,
                        "start_date": !this.form.start_date || this.form.start_date === ""
                            ? null : this.form.start_date,
                        "sim_number": !this.form.sim_number || this.form.sim_number === ""
                            ? null : this.form.sim_number,
                        "sim_status": !this.form.sim_status || this.form.sim_status === ""
                            ? false : this.form.sim_status,
                        "sim_expired_date": !this.form.sim_expired_date || this.form.sim_expired_date === ""
                            ? null : this.form.sim_expired_date,
                        "vehicle_number": !this.form.vehicle_number || this.form.vehicle_number === ""
                            ? null : this.form.vehicle_number,
                        "myr_id": !this.form.myr_id || this.form.myr_id === ""
                            ? null : this.form.myr_id,
                        "loop_id": !this.form.loop_id || this.form.loop_id === ""
                            ? null : this.form.loop_id,
                        "employee_id": !this.form.employee_id || this.form.employee_id === ""
                            ? null : this.form.employee_id,
                        "previous_company_contact": !this.form.previous_company_contact || this.form.previous_company_contact === ""
                            ? null : this.form.previous_company_contact,
                        "previous_company_name": !this.form.previous_company_name || this.form.previous_company_name === ""
                            ? null : this.form.previous_company_name,
                        "previous_company_role": !this.form.previous_company_role || this.form.previous_company_role === ""
                            ? null : this.form.previous_company_role,
                    }
                }
            };

            let documentSim = null;
            if (this.form.other_document_sim && this.form.other_document_sim !== "") {
                documentSim = {};
                documentSim.document = this.form.other_document_sim.file_path;
                if (this.form.other_document_sim.is_valid !== undefined && this.form.other_document_sim.is_valid !== null) {
                    documentSim.is_valid = this.form.other_document_sim.is_valid;
                }
                if (this.form.other_document_sim.notes !== undefined && this.form.other_document_sim.notes !== null) {
                    documentSim.notes = this.form.other_document_sim.notes;
                }
            }
            nForm.other_document_sim = documentSim;

            let documentStnk = null;
            if (this.form.other_document_stnk && this.form.other_document_stnk !== "") {
                documentStnk = {};
                documentStnk.document = this.form.other_document_stnk.file_path;
                if (this.form.other_document_stnk.is_valid !== undefined && this.form.other_document_stnk.is_valid !== null) {
                    documentStnk.is_valid = this.form.other_document_stnk.is_valid;
                }
                if (this.form.other_document_stnk.notes !== undefined && this.form.other_document_stnk.notes !== null) {
                    documentStnk.notes = this.form.other_document_stnk.notes;
                }
            }
            nForm.other_document_stnk = documentStnk;

            await this.patchJobDetail({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },

        validateData() {
            this.errorsD = {};
            let isValidated = true;
            const phoneRgxp = /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{3,6}$/im;

            if (this.form.sim_number && !/^\d*$/.test(this.form.sim_number)) {
                this.errorsD["sim_number"] = "Nomor SIM tidak valid";
                isValidated = false;
            }
            if (this.form.previous_company_contact && !phoneRgxp.test(this.form.previous_company_contact)) {
                this.errorsD["previous_company_contact"] = "Nomor Kontak Perusahaan Sebelumnya tidak valid";
                isValidated = false;
            }

            return isValidated ? true : false;
        },
    }
};
</script>

<style scoped></style>
