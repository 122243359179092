<template>
  <div>
    <section>
      <button
        v-if="$can('EDIT', 'worker-mitra')"
        class="uk-button uk-button-default uk-border-rounded uk-margin-right"
        style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
        @click="$emit('action', true)"
      >Edit</button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
        v-for="(detail, dKey) in details"
        :key="dKey"
      >
        <div class="uk-width-1-2">{{ detail.label }}</div>

        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-auto">:&nbsp;</div>
            <div
              v-if="['other_document_sim', 'other_document_stnk'].includes(detail.value)"
              class="uk-width-expand"
            >
              <span
                v-if="data[detail.value]"
                style="text-decoration: underline; color: #0ABAB5; cursor: pointer;"
                @click="openImage({ ...detail, file_url: data[detail.value].file_url})"
              >
                Lihat Lampiran
              </span>
              <span v-else style="text-decoration: underline;">
                Lihat Lampiran
              </span>
              <span v-if="data[detail.value]" class="uk-margin-left">
                <span
                  v-if="!data[detail.value].is_checked"
                  style="text-decoration: underline; color: #0275D8; cursor: pointer;"
                  @click="validateDocument(detail.value)"
                >
                  <img :src="`${images}/view-board.svg`" alt="" style="width:20px" />
                  Validasi Dokumen
                </span>
                <span
                  v-else-if="data[detail.value].is_valid"
                  style="text-decoration: underline; color: #5FD855;"
                >
                  <img :src="`${images}/verif-green.svg`" alt="" style="width:20px" />
                  Dokumen Valid
                </span>
                <span v-else style="text-decoration: underline; color: #FFC737;">
                  <img :src="`${images}/warning-small-yellow.svg`" alt="" style="width:20px" />
                  Dokumen Tidak Valid
                </span>
              </span>
              <span v-else class="uk-margin-left">
                <img :src="`${images}/view-board.svg`" alt="" style="width:20px" />
                Validasi Dokumen
              </span>
            </div>
            <div v-else-if="detail.value == 'sim_status'" class="uk-width-expand">
              {{ getRealData("sim_status", data.detail.job[detail.value]) }}
            </div>
            <div v-else-if="['start_date', 'sim_expired_date'].includes(detail.value)" class="uk-width-expand">
              {{ data.detail.job[detail.value] ? formatter.dateFormatSlash(data.detail.job[detail.value]) : '-' }}
            </div>
            <div v-else-if="['employment_type'].includes(detail.value)" class="uk-width-expand">
                <div v-if="(data.detail.job[detail.value] || []).length > 0" class="uk-flex uk-flex-left uk-flex-middle"> 
                    <p
                        v-for="(employmentType, index) in (data.detail.job[detail.value] || [])"
                        :key="index"
                        class="uk-margin-remove-top uk-margin-remove-bottom"
                        :class="[index !== ((data.detail.job[detail.value] || []).length -1) ? 'uk-margin-small-right' : '']"
                    >{{ employmentType }}<span v-if="index !== ((data.detail.job[detail.value] || []).length -1)">,</span> </p>
                </div>
                <span v-else>-</span>
            </div>
            <div v-else class="uk-width-expand">
              {{ data.detail.job[detail.value] }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Upload Input -->
    <input ref="upload" style="display: none" type="file" @change="previewImage" />
    <!-- Modal Photo -->
    <section>
      <div id="modal-center" class="uk-flex-top" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header uk-text-center">
              <h2 class="uk-modal-title">Lampiran</h2>
          </div>
          <div class="uk-modal-body">
            <img :src="detailWorkerImgChecker(imgSrc.file_url, isEdit, newSrc)" alt="lampiran" height="350" style="margin: auto; display: block">
          </div>
          <div class="uk-modal-footer uk-text-center">
            <section v-if="isEdit">
              <button
                class="uk-button uk-button-default"
                type="button"
                style="border-color: #0ABAB5; color: #0ABAB5; margin: 0 1rem;"
                @click="cancelUpload"
              >Cancel</button>
              <button
                class="uk-button uk-button-primary"
                type="button"
                style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff; margin: 0 1rem;"
                @click="uploadFileVerification"
              >Save</button>
            </section>
            <section v-else>
              <button
                class="uk-button uk-button-default"
                type="button"
                style="border-color: #0ABAB5; color: #0ABAB5; margin: 0 1rem;"
                @click="triggerUpload()"
              >Ubah Lampiran</button>
              <button
                class="uk-button uk-button-primary"
                type="button"
                style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff; margin: 0 1rem;"
                @click="open(imgSrc.file_url)"
              >Preview</button>
            </section>
          </div>
        </div>
      </div>
    </section>

    <ValidateDocument
      v-if="docValidating"
      :document="selectedDoc"
      :user-id="$route.params.mitra_id"
      @close="afterValidation()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDangerCustom } from '@/utils/notification';
import { detailWorkerImgChecker } from '@/utils/helper';
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    components: {
        ValidateDocument: () => import('@/components/globals/modals/ValidateDocumentModal.vue'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            imgSrc: {},
            oldSrc: {},
            newSrc: {},
            isLoading: false,
            isEdit: false,
            details: [
                { label: 'Employment Type', value: 'employment_type' },
                { label: 'Posisi', value: 'position' },
                { label: 'Divisi', value: 'division' },
                { label: 'Hub Penempatan', value: 'placement' },
                { label: 'Tanggal Mulai Bekerja', value: 'start_date' },
                { label: 'Status SIM', value: 'sim_status' },
                { label: 'Nomor SIM', value: 'sim_number' },
                { label: 'Tanggal Kadaluarsa SIM', value: 'sim_expired_date' },
                { label: 'Foto SIM', value: 'other_document_sim', api: 'SIM' },
                { label: 'Foto STNK', value: 'other_document_stnk', api: 'STNK' },
                { label: 'Nomor Polisi Kendaraan (Motor/Mobil)', value: 'vehicle_number' },
                { label: 'Nomor Induk Pegawai MYR', value: 'myr_id' },
                { label: 'Client Mitra ID', value: 'loop_id' },
                { label: 'Kontak Perusahaan Sebelumnya', value: 'previous_company_contact' },
                { label: 'Nama Pihak Perusahaan Sebelumnya', value: 'previous_company_name' },
                { label: 'Hubungan Pihak Perusahaan Sebelumnya', value: 'previous_company_role' },
            ],
            data: null,
            formatter: formatter,
            detailWorkerImgChecker,
            docValidating: false,
            selectedDoc: '',
        };
    },
    beforeMount() {
        if (window.UIkit.modal("#modal-center")) window.UIkit.modal("#modal-center").$destroy(true);
    },
    async mounted() {
        this.getMitraJobDetail();
    },
    methods: {
        ...mapActions({
            uploadDocument: 'other_document/uploadDocument',
            getJobDetail: 'mitra/getJobDetail',
        }),

        openImage(data) {
            this.imgSrc = data;
            this.oldSrc = {};
            this.newSrc = {};
            this.isEdit = false;
            window.UIkit.modal("#modal-center").show();
        },

        triggerUpload() {
            this.$refs.upload.click();
        },

        open(url) {
            window.open(formatter.getMyrobinFilePreviewerUrl(url), '_blank').focus();
        },

        previewImage(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    const reader = new FileReader();
                    this.newSrc = e.target.files[0];

                    reader.readAsDataURL(this.newSrc);
                    reader.onload = () => {
                        this.oldSrc = JSON.parse(JSON.stringify(this.imgSrc));
                        this.imgSrc.file_url = reader.result;
                    };

                    this.isEdit = true;
                    this.$refs.upload.value = null;
                }
            }
        },

        checkFile(fileSize, fileExt) {
            if (fileSize / 1024 / 1024 > 10) {
                notificationDangerCustom('File terlalu besar, maksimal file berukuran 10 MB');
                this.$refs.upload.value = null;
                return false;
            }

            const extension = ["png", "jpg", "jpeg", "pdf"];
            const splitImg = fileExt.split("/");
            let imgMime = splitImg[splitImg.length - 1];

            if (!extension.includes(imgMime)) {
                notificationDangerCustom('Ekstensi file tidak dikenali');
                this.$refs.upload.value = null;
                return false;
            }

            return true;
        },

        cancelUpload() {
            this.newSrc = {};
            this.imgSrc = JSON.parse(JSON.stringify(this.oldSrc));
            this.oldSrc = {};
            this.isEdit = false;
        },

        getRealData(variable, value) {
            if (variable === "sim_status") {
                switch (value) {
                case true:
                    return "Aktif";
                default:
                    return "Kadaluarsa";
                }
            }
        },

        async uploadFileVerification() {
            if (this.newSrc) {
                let formData = new FormData();
                formData.append('file', this.newSrc);

                const result = await this.uploadDocument({
                    api: this.imgSrc.api,
                    user_id: this.$route.params.mitra_id,
                    payload: formData
                });

                if (result.status === 'OK') {
                    notificationSuccess(`Upload successful`);
                    this.data[this.imgSrc.value] = result.result.document_url;
                    this.imgSrc.file_url = result.result.document_url.file_url;
                    this.isEdit = false;
                } else {
                    notificationDangerCustom('Failed to upload!');
                }
            }

            await window.UIkit.modal("#modal-center").hide();
        },

        async getMitraJobDetail() {
            this.isLoading = true;
            const data = await this.getJobDetail(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result;
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        },
        validateDocument(value) {
            this.docValidating = true;
            this.selectedDoc = value;
        },
        async afterValidation() {
            await this.getMitraJobDetail();
            this.docValidating = false;
            this.selectedDoc = '';
        },
    }
};
</script>

<style scoped></style>
